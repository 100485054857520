import React from 'react'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

const default404 = () => {
  return (
    <Layout>
      <section className="se-section text-center padding-404">
        <div className="container-1">
          <div className="row">
            <div className="col-md-12">
              <h1 className="f-45 h1-404-color">Ooops! 404</h1>
              <StaticImage
                className="w-50 mx-auto"
                alt="404"
                src="../resources/images/vectors/undraw_startled_8p0r.svg"
                placeholder="tracedSVG"
              />
            </div>
            <div className="col-md-10 offset-md-1">
              <p className="hero-description-dark ">
                Unfortunately the resource you are looking for does not exist on
                this server. You can visit our{' '}
                <b>
                  <a href="https://lenses.io">homepage</a>
                </b>
                ,{' '}
                <b>
                  <a href="https://www.google.com/?q=site:lenses.io">
                    search our website{' '}
                  </a>
                </b>
                or{' '}
                <b>
                  <a href="/contact-us/">contact us</a>
                </b>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default default404
